import { SHOW_USER, LOAD_FORM_USER, EDIT_USER } from "constants/actionTypes";
import { USER_NOT_FOUND } from "constants/actionTypes";
import { ERROR_EMAIL_OTP } from "constants/actionTypes";
import { SUCCESSFUL_WHATSAPP_OTP } from "constants/actionTypes";
import { SUCCESSFUL_PHONE_OTP } from "constants/actionTypes";
import { RESPONSE_OTP_RESETPASSWORD } from "constants/actionTypes";
import { SHOW_USER_HISTORY } from "constants/actionTypes";
import { USER_HISTORY_LIST } from "constants/actionTypes";
import { ERROR_PHONE_OTP } from "constants/actionTypes";
import { ERROR_WHATSAPP_OTP } from "constants/actionTypes";
import { RESET_OTP_VARIABLES } from "constants/actionTypes";
import { SUCCESSFUL_EMAIL_OTP } from "constants/actionTypes";
import { MESSAGE_SENDED } from "constants/actionTypes";
import { ERROR_EDIT_USER } from "constants/actionTypes";
import { APPROVE_USER_HISTORY } from "constants/actionTypes";
import { REJECT_USER_HISTORY } from "constants/actionTypes";
import { EDIT_PASSWORD_USER, DELETE_USER } from "constants/actionTypes";
import { USER_EDIT_FOR_REVISION } from "constants/actionTypes";
import { USER_HISTORY_NOTIFICATION_LIST } from "constants/actionTypes";
import { LOADING_USER_HISTORY_NOTIFICATION } from "constants/actionTypes";
import { USER_HISTORY_APPROVED_LIST, USER_HISTORY_REJECTED_LIST, USER_HISTORY_REVISION_LIST } from "../constants/actionTypes";

const initialState = {
  editError: false,
  error_email_otp: false,
  successful_email_otp: false,
  successful_whatsapp_otp: false,
  error_whatsapp_otp: false,
  successful_phone_otp: false,
  error_phone_otp: false,
  approve_user_history: false,
  reject_user_history: false,
  user_edit_for_revision: false,
  user_history_revision_list: [],
  user_history_approved_list: [],
  user_history_rejected_list: [],
  user_history_notification_list: [],
  loading_history_notification_loading: false,
  show_user: {
    id: "",
    email: "",
    username: "",
    language: "",
    language_grader: "",
    first_name: "",
    last_name: "",
    country: "",
    studentgroup: {
      group: {
        name: ""
      },
      embassador: {
        first_name: "",
        last_name: ""
      }
    }
  },
  active_user: {
    roles: []
  },
  otp_value: {
    otp_code: "",
  },
  response_otp_resetpassword: { id: "" },
  user_history_list: [],
  show_user_history: {
    original_user_id: { full_name: "" },
    changed_by: { full_name: "" }
  },
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_USER:
      return Object.assign({}, state, {
        show_user: action.payload
      });

    case EDIT_USER:
      return Object.assign({}, state, {
        edit_user: action.payload
      });
    case ERROR_EDIT_USER:
      return Object.assign({}, state, {
        editError: true
      })
    case LOAD_FORM_USER:
      return Object.assign({}, state, {
        data: action.data
      });

    case EDIT_PASSWORD_USER:
      return Object.assign({}, state, {
        edit_password: action.payload
      });

    case DELETE_USER:
      return Object.assign({}, state, {
        delete_user: action.payload
      });
    case USER_NOT_FOUND:
      return Object.assign({}, state, {
        message_sended: false,
        errorGmail: true
      })

    case MESSAGE_SENDED:
      return Object.assign({}, state, {
        errorGmail: false,
        message_sended: true
      });
    case RESET_OTP_VARIABLES:
      return Object.assign({}, state, {
        successful_email_otp: false,
        error_email_otp: false,
        successful_whatsapp_otp: false,
        error_whatsapp_otp: false,
        successful_phone_otp: false,
        error_phone_otp: false,
        response_otp_resetpassword: { id: "" }
      });
    case SUCCESSFUL_EMAIL_OTP:
      return Object.assign({}, state, {
        successful_email_otp: true,
      });
    case ERROR_EMAIL_OTP:
      return Object.assign({}, state, {
        error_email_otp: true,
      });
    case SUCCESSFUL_WHATSAPP_OTP:
      return Object.assign({}, state, {
        successful_whatsapp_otp: true,
      });
    case ERROR_WHATSAPP_OTP:
      return Object.assign({}, state, {
        error_whatsapp_otp: true,
      });
    case SUCCESSFUL_PHONE_OTP:
      return Object.assign({}, state, {
        successful_phone_otp: true,
      });
    case ERROR_PHONE_OTP:
      return Object.assign({}, state, {
        error_phone_otp: true,
      });
    case RESPONSE_OTP_RESETPASSWORD:
      return Object.assign({}, state, {
        response_otp_resetpassword: action.payload,
      });
    case USER_HISTORY_LIST:
      return Object.assign({}, state, {
        user_history_list: action.payload,
      });
    case SHOW_USER_HISTORY:
      return Object.assign({}, state, {
        show_user_history: action.payload,
      });
    case APPROVE_USER_HISTORY:
      return Object.assign({}, state, {
        approve_user_history: action.payload,
      });
    case REJECT_USER_HISTORY:
      return Object.assign({}, state, {
        reject_user_history: action.payload,
      });
    case USER_EDIT_FOR_REVISION:
      return Object.assign({}, state, {
        user_edit_for_revision: action.payload,
      });
    case USER_HISTORY_REVISION_LIST:
      return Object.assign({}, state, {
        user_history_revision_list: action.payload,
      });
    case USER_HISTORY_APPROVED_LIST:
      return Object.assign({}, state, {
        user_history_approved_list: action.payload,
      });
    case USER_HISTORY_REJECTED_LIST:
      return Object.assign({}, state, {
        user_history_rejected_list: action.payload,
      });
    case USER_HISTORY_NOTIFICATION_LIST:
      return Object.assign({}, state, {
        user_history_notification_list: action.payload,
      });
    case LOADING_USER_HISTORY_NOTIFICATION:
      return Object.assign({}, state, {
        loading_user_history_notification: action.payload,
      });
  }
  return state;
}